import React from "react";
import "./index.scss";
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Link } from "gatsby";
import InquiryForm from "../components/InquiryForm";
import NewsletterForm from "../components/NewsletterForm";
import { Server } from "http";
import Footer from "../components/Footer";


  // In Gatsby, Page == Component
export default function Index() {

    return (
        <main className="container">

            <title>Oodal</title>
            <div className="top-container">
                <header>
                    <nav>
                        <div className="logo-container">
                            <Link to="/">
                                <image className="logo"/>
                            </Link>    
                        </div>
                        <div className="menuItems">
                            <a href="#inquiryForm" className="navButton menuItem-1">Contact</a>
                            <Link to="/login" className="navButton menuItem-2">Partner Login</Link>
                        </div>
                    </nav>
                </header>

                <section className="hero">
                    <h1>
                        <span className="heroWhite">Ontological Applications</span>
                        <span className="heroBold">& Artificial Intelligence</span>
                        <span className="heroWhite">Solutions</span>
                    </h1>
                    <h2>Empowering Intelligence Everywhere</h2>
                    <hr className="heroLine"></hr>
                    <div className="heroDesc">
                        <div>
                            Oodal is at the forefront of Artificial Intelligence (AI), knowledge representation, and application of ontological systems.
                            We specialize in developing advanced AI systems and ontology-based solutions that transform raw data into actionable
                            intelligence. Our innovative approach combines the power of artificial intelligence with the
                            structured framework of ontologies to create smart, adaptive systems that can understand,
                            reason, and learn.
                        </div>
                        <div>
                            From natural language processing and machine learning to expert systems and knowledge graphs, we
                            deliver tailored AI solutions that empower businesses across industries to make better
                            decisions, automate complex processes, and gain deeper insights from their data. Our mission is
                            to make intelligent systems accessible and beneficial in every aspect of life and business,
                            truly "Empowering Intelligence Everywhere."
                        </div>
                        <div>
                            Whether you're looking to optimize operations, enhance customer experiences, or drive
                            innovation, our team of business experts, data scientists, AI engineers, and ontology specialists is
                            dedicated to helping you harness the full potential of AI and ontological applications to stay
                            ahead in the next iteration of the Digital Age. An age we refer to as <i>The Age of Intelligence</i>.
                        </div>
                    </div>
                    <a href="#inquiryForm">Get in touch</a>
                </section>

                <section id="about" className="about">
                    <h2>Industry-Specific Intelligent Solutions</h2>
                    <p>
                        In today's rapidly evolving business landscape, we understand that one-size-fits-all approaches fall short. At Oodal, we specialize in crafting bespoke AI and ontology-based solutions that align precisely with your industry's unique challenges and opportunities. Our team collaborates closely with your organization to engineer cutting-edge enterprise systems that not only address current demands but are also built with future scalability in mind. We ensure seamless integration with your existing workflows, enhancing efficiency without disrupting operations. By leveraging our expertise in AI and ontological applications, we empower your business to stay ahead of the curve, turning industry-specific complexities into competitive advantages in the Age of Intelligence.
                    </p>
                    <ul className="subabout-col-1">
                        <p>Consulting and Engineering Solutions</p>
                        <hr></hr>
                        <li>
                            <span className="chatIcon"/>
                            Observability and AI Solutions
                        </li>
                        <li>
                            <span className="buildingIcon"/>
                            AI Integration and Customization
                        </li>
                        <li>
                            <span className="branchIcon"/>
                            Ontological Software Applications
                        </li>
                        <li>
                            <span className="branchIcon"/>
                            Customized Ontologies
                        </li>
                    </ul>
                    <ul className="subabout-col-2">
                        <p>Products and Platforms</p>
                        <hr></hr>
                        <li>
                            <span className="medCrossIcon"/>
                            DxCell<button>Solution Partnership</button>
                        </li>
                        <li>
                            <span className="brainIcon"/>
                            Oodal "Minerva" AI Framework
                        </li>

                        <li>
                            <span className="questionIcon"/>
                            Oodal
                            <span className="histografColor">Histograf</span>
                            Associative Memory Server
                        </li>
                    </ul>
                </section>
            </div>
            <div className="bottom-container">
                <section className="mission-container">
                    <div className="mission-top-background"></div>
                    <div className="mission-content about-mission">
                        <h2>Our mission</h2>
                        <p className="about1">
                            We are committed to revolutionizing decision-making processes by leveraging advanced AI and
                            ontological systems.
                            Our goal is to empower organizations with intelligent, adaptive frameworks that embody the
                            spirit of the OODA (Observe-Orient-Decide-Act) loop, enabling faster, more accurate, and
                            data-driven decisions in complex environments.
                        </p>
                        <p className="ooda-definition">What is an OODA loop?</p>
                        <div className="ooda-graphic-container">
                            <image className="ooda-graphic"></image>
                        </div>
                        <div className="about-ending">
                            <ul className="ooda-col-1">
                                <li>
                                    <p>
                                        <b>Observe:</b> Enhance data collection and sensory input through AI-powered systems,
                                        ensuring comprehensive and real-time situational awareness.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Orient:</b> Utilize ontological frameworks and machine learning to analyze and
                                        contextualize information, facilitating deeper understanding and pattern
                                        recognition.
                                    </p>
                                </li>
                            </ul>
                            <ul className="ooda-col-1">
                                <li>
                                    <p>
                                        <b>Decide:</b> Employ advanced algorithms and expert systems to generate and evaluate
                                        potential courses of action, supporting informed decision-making.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Act:</b> Implement intelligent automation and adaptive systems to execute decisions
                                        efficiently, while continuously learning and improving from outcomes.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div style={{flexBasis: "100%", height: "0"}}></div>
                        <div className="about-why">
                            By optimizing each phase of the OODA loop with cutting-edge AI and ontological
                            applications, we aim to give organizations a decisive edge in today's fast-paced,
                            data-rich environment.
                            <br/>
                            The future is bright for AI-driven decision-making and we're here for it.
                        </div>
                    </div>
                    <div className="mission-bottom-background"></div>
                </section>

                <section id="inquiryForm" className="bottomGraphic">
                    <div className="webform inquiry-form">
                        <InquiryForm/>
                    </div>
                </section>

                <Footer />
            </div>    

        </main>
    )
}



