import * as Yup from "yup";

export const InquirySchema = Yup.object(
    {
        Inquiry: new Yup.ObjectSchema({
            Name: Yup
                .string(),
            Email: Yup
                .string()
                .email("email is valid")
                .required("email is required"),
            Message: Yup
                .string()
                .min(10, "Your message must be longer than ten characters")
                .max(1000, "Your message is too long, must be less than 1000 characters")
                .required("a message is required")
        })
    }
).required();

export interface InquiryValidationModel {
    Inquiry: {
        Name: string,
        Email: string,
        Message: string
    }
}